import { useCallback, useEffect, useState } from 'react'

const localStorageVersion = '1'

export const useLocalStorage = (defaultValue, localStorageKey) => {
  const [value, setValue] = useState(defaultValue)

  const versionedKey = `${localStorageKey}-version-${localStorageVersion}`

  const getFromStorage = useCallback(() => {
    const storageValue =
      window.localStorage.getItem(versionedKey) ?? JSON.stringify('')

    try {
      setValue(JSON.parse(storageValue))
    } catch {
      window.localStorage.removeItem(versionedKey)
    }
  }, [versionedKey])

  useEffect(() => {
    getFromStorage()

    window.addEventListener('storage', getFromStorage)

    return () => window.removeEventListener('storage', getFromStorage)
  }, [getFromStorage])

  const updateValue = useCallback(
    (newValue) => {
      window.localStorage.setItem(versionedKey, JSON.stringify(newValue))

      window.dispatchEvent(new Event('storage'))
    },
    [versionedKey]
  )

  useEffect(() => {
    if (!window.localStorage.getItem(versionedKey)) updateValue(defaultValue)
  }, [defaultValue, versionedKey, updateValue])

  return [value, updateValue]
}
