import { useEffect, useRef, useState } from 'react'

import { createPortal } from 'react-dom'
import { useExitAnimation, useOnHover } from '../../utils/'

export const Tooltip = ({ target, children, openDelay, align = 0.5 }) => {
  const [anchor, setAnchor] = useState({ x: 0, y: 0 })

  /** Actually decides whether to open or not */
  const [isOpen, setIsOpen] = useState(false)

  /** Whether target is being hovered */
  const [targetIsHovered, setTargetIsHovered] = useState(false)

  /** Timeout for showing the tooltip on hover */
  const openTimeout = useRef()

  const [element, setElement] = useState(null)

  const exiting = useExitAnimation({ isOpen, animationTarget: element })

  useOnHover(
    target,
    () => setTargetIsHovered(true),
    () => setTargetIsHovered(false)
  )

  useEffect(() => {
    // Clear any active timeouts
    if (openTimeout.current) {
      clearTimeout(openTimeout.current)
      openTimeout.current = undefined
    }

    if (!target) {
      setIsOpen(false)

      return
    }

    // Get anchor position
    const { x, y, width, height } = target.getBoundingClientRect()

    setAnchor({
      x: x + width / 2,
      y: y + height,
    })

    // Call instantly if closing or if there is no delay set
    if (!targetIsHovered || !openDelay) setIsOpen(targetIsHovered)
    else {
      openTimeout.current = setTimeout(() => setIsOpen(true), openDelay)
    }
  }, [openDelay, target, targetIsHovered])

  return createPortal(
    (isOpen || exiting) && (
      <div
        ref={setElement}
        className={`tooltip ${isOpen ? '' : 'closing'}`}
        style={{
          '--left': `${anchor.x}px`,
          '--top': `${anchor.y}px`,
          '--align': `${align * 100}%`,
        }}
      >
        {children}
      </div>
    ),
    document.body
  )
}
