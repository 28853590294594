export const HIGHLIGHT_TYPE = 'dynamicHighlighted'

export const customTheme = {
  base: 'vs',
  inherit: true,
  rules: [
    // Base text color
    { token: '', foreground: '#7b7d86', fontStyle: 'normal' },

    // Comments
    { token: 'comment', foreground: '#7b7d86', fontStyle: 'italic' },

    // Strings
    { token: 'string', foreground: '#5f616b' },

    // Keywords, classes, functions
    { token: 'keyword', foreground: '#3a4d84', fontStyle: 'bold' },
    { token: 'class', foreground: '#3a4d84', fontStyle: 'bold' },
    { token: 'function', foreground: '#3a4d84', fontStyle: 'bold' },

    // Variables, properties
    { token: 'variable', foreground: '#7b7d86' },
    { token: 'property', foreground: '#7b7d86' },

    // Other
    { token: 'number', foreground: '#7b7d86' },
    { token: 'type', foreground: '#7b7d86' },
    { token: 'delimiter', foreground: '#7b7d86' },
    { token: 'delimiter.xml', foreground: '#7b7d86' },

    // Target highlighted words
    {
      token: HIGHLIGHT_TYPE,
      foreground: '#4779ff',
      background: '#4779ff29',
      fontStyle: 'bold',
    },
  ],
  colors: {
    'editor.foreground': '#7b7d86',
    'editor.background': '#ffffff',
    'editor.lineHighlightBackground': '#ffffff',
    'editor.lineHighlightBorder': '#ffffff',
    'editor.selectionBackground': '#4779ff29',
    'editor.selectionHighlightBackground': '#f4f4f6',
    'editor.cursor': '#4779ff',
    'editor.findMatchBackground': '#4779ff29',
    'editor.findMatchHighlightBackground': '#f4f4f6',
    'scrollbarSlider.background': '#7b7d86', // Color of the scrollbar slider
    'scrollbarSlider.hoverBackground': '#5f616b', // Color of the scrollbar slider when hovering
    'scrollbarSlider.activeBackground': '#3a4d84', // Color of the scrollbar slider when clicked
  },
}
