import { HIGHLIGHT_TYPE, customTheme } from './customTheme'

const legend = {
  tokenTypes: [HIGHLIGHT_TYPE],
  tokenModifiers: [],
}

// Chat GPT generated
const highlightProvider = (highlightStrings) => ({
  provideDocumentSemanticTokens: async (model) => {
    const text = model.getValue()
    const data = []

    let previousLine = 0
    let previousStart = 0

    const lines = text.split(/\r?\n/)

    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      const line = lines[lineIndex]

      for (const highlightTarget of highlightStrings) {
        const columnIndex = line.indexOf(highlightTarget)

        if (columnIndex === -1) continue

        const endColumn = columnIndex + highlightTarget.length

        const deltaLine = lineIndex - previousLine

        const deltaStart =
          deltaLine === 0 ? columnIndex - previousStart : columnIndex

        const length = endColumn - columnIndex

        data.push(
          deltaLine,
          deltaStart,
          length,
          legend.tokenTypes.indexOf(HIGHLIGHT_TYPE),
          0
        )

        previousLine = lineIndex
        previousStart = columnIndex
      }
    }

    return {
      data,
      resultId: Date.now().toString(),
    }
  },
  releaseDocumentSemanticTokens: () => {},
  getLegend: () => legend,
})

export const monacoCustomizer = (highlightStrings, language) => (monaco) => {
  monaco.editor.defineTheme('dynamic', customTheme)

  monaco.languages.registerDocumentSemanticTokensProvider(
    language,
    highlightProvider(highlightStrings)
  )
}
