import { useState } from 'react'
import { UpdateUserView } from './UpdateUserView'
import { UpdateUserWithModalView } from './UpdateUserWithModalView'
import { UserView } from './UserView'
import { DynamicWidget } from '@dynamic-labs/sdk-react-core'

const tabs = {
  updateUser: UpdateUserView,
  updateUserWithModal: UpdateUserWithModalView,
}

/** This view shows user data and provides a switch between UpdateUserView and UpdateUserWithModalView */
export const TabView = () => {
  const [activeTab, setActiveTab] = useState('updateUser')

  const TabContent = tabs[activeTab]

  return (
    <div className="tabs-container">
      <DynamicWidget />

      <p className="edit-widget-notice">
        You can also update your profile by clicking our widget right above and
        then clicking "Edit profile"
      </p>

      <UserView />

      <div className="tabs-container__wrapper">
        <div className="tabs-container__picker">
          {Object.keys(tabs).map((tabName) => (
            <p
              key={tabName}
              className={`tabs-container__picker__option ${
                tabName === activeTab && 'active'
              }`}
              onClick={() => setActiveTab(tabName)}
            >
              {tabName}
            </p>
          ))}
        </div>

        <div className="tabs-container__content">
          <TabContent />
        </div>
      </div>
    </div>
  )
}
