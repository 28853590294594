import { useMemo } from "react";
import { connectorsMap, useProjectSetupVariables } from "../../utils";

const highlightStrings = [
  "<DynamicWagmiConnector>",
  "</DynamicWagmiConnector>",
  "DynamicWagmiConnector",
  "EthersExtension",
  ...Object.values(connectorsMap).map(({ connector }) => connector),
];

const includeIf = (condition, content) => (condition ? content : "");

export const useQuickstartCode = () => {
  const { client, connectors, hasWagmi } = useProjectSetupVariables();

  const walletConnectors = useMemo(() => {
    if (connectors.length === 0) return "";

    if (connectors.length === 1)
      return `
        walletConnectors: [${connectorsMap[connectors[0]].connector}],`;

    return `
        walletConnectors: [${connectors.map(
          (connector) => `
          ${connectorsMap[connector].connector}`
        )}
        ],`;
  });

  const output = `\
import {
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react-core";\
${includeIf(
  client === "ethers",
  `
import { EthersExtension } from "@dynamic-labs/ethers-v5";`
)}
${includeIf(
  hasWagmi,
  `\
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
`
)}\
${connectors
  .map(
    (connectorName, index) => `${index === 0 ? "\n" : ""}\
import { ${connectorsMap[connectorName].connector} } from "${
      connectorsMap[connectorName].packagePath
    }";
`
  )
  .join("")}\

export default function App() {
  return (
    <DynamicContextProvider
      settings={{
        // Find your environment id at https://app.dynamic.xyz/dashboard/developer
        environmentId: "REPLACE-WITH-YOUR-ENVIRONMENT-ID",${includeIf(
          client === "ethers",
          `
        walletConnectorExtensions: [EthersExtension],`
        )}${walletConnectors}
      }}
    >
${
  hasWagmi
    ? `\
      <DynamicWagmiConnector>
        <DynamicWidget />
      </DynamicWagmiConnector>`
    : `\
      <DynamicWidget />`
}
    </DynamicContextProvider>
  );
};\
`;

  return { output, highlightStrings };
};
