import { useState } from 'react'
import { connectorsMap } from '../../../utils'
import { Tooltip } from '../../Tooltip/'
import { ChainIcon } from './ChainIcon/'

function Chain({ option, isSelected, onClick, tooltipAlign }) {
  const [element, setElement] = useState(null)

  return (
    <div ref={setElement} className="item" onClick={onClick}>
      <Tooltip target={element} openDelay={200} align={tooltipAlign}>
        {option}
      </Tooltip>

      <ChainIcon selected={isSelected} chain={option} />
    </div>
  )
}

export const ConnectorSelect = ({ setValue, value }) => {
  const isSelected = (option) => value.includes(option)

  const handleClick = (optionValue) => {
    // If it was already selected, un-select it
    if (isSelected(optionValue)) {
      const optionIndex = value.indexOf(optionValue)

      value.splice(optionIndex, 1)

      setValue([...value])

      return
    }

    setValue([...value, optionValue])
  }

  return (
    <div className="radio-select">
      <p className="label">Chains & add-ons</p>

      <div className="options">
        {Object.keys(connectorsMap).map((option, index, array) => {
          // Maps index 0 to align 0, last index to align 1
          const tooltipAlignMap = { 0: 0.2, [array.length - 1]: 0.8 }

          return (
            <Chain
              // Align to center unless the map has an override
              tooltipAlign={tooltipAlignMap[index] ?? 0.5}
              option={option}
              isSelected={isSelected(option)}
              onClick={() => handleClick(option)}
              key={option}
            />
          )
        })}
      </div>
    </div>
  )
}
