import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { EmailIcon, UserIcon } from '../../../assets'

/** This just shows the user's information */
export const UserView = () => {
  // Get the current user's information from the DynamicContext
  const { user } = useDynamicContext()

  return (
    <div className="user-data">
      <div className="user-data__fields">
        <div className="user-data__fields__field">
          <UserIcon />
          <div className="user-data__fields__field__rows">
            <small>First Name</small>
            <p className={!user.firstName?.length && 'empty'}>
              {user.firstName || 'empty'}
            </p>
          </div>
        </div>

        <div className="user-data__fields__field">
          <EmailIcon />
          <div className="user-data__fields__field__rows">
            <small>Email</small>
            <p className={!user.email?.length && 'empty'}>
              {user.email || 'empty'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
