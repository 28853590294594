import { useRef, useState } from 'react'
import { useObserveResize } from '../../utils/'

export const Toggle = ({
  setValue,
  value,
  leftValue = false,
  rightValue = true,
  strongColors = false,
  label,
}) => {
  const [leftElement, setLeftElement] = useState(null)
  const [rightElement, setRightElement] = useState(null)

  const [minWidth, setMinWidth] = useState(15)
  const minWidthRef = useRef(minWidth)
  const timeoutRef = useRef(null)

  const updateMinWidth = (newValue) => {
    if (newValue > minWidthRef.current) {
      minWidthRef.current = newValue

      if (timeoutRef.current) clearTimeout(timeoutRef)
      timeoutRef.current = setTimeout(() => {
        setMinWidth(newValue)
        timeoutRef.current = null
      }, 0)
    }
  }

  useObserveResize(leftElement, ([{ contentBoxSize }]) => {
    updateMinWidth(contentBoxSize[0].inlineSize)
  })

  useObserveResize(rightElement, ([{ contentBoxSize }]) => {
    updateMinWidth(contentBoxSize[0].inlineSize)
  })

  return (
    <div
      className={`toggle-wrapper ${strongColors ? 'strong-colors' : ''}`}
      onClick={() => setValue(value === leftValue ? rightValue : leftValue)}
      style={{
        '--min-width': `${minWidth}px`,
      }}
    >
      {label && <p className="label">{label}</p>}

      <div className={`toggle ${value === rightValue ? 'right' : ''}`}>
        <p ref={setLeftElement} className="value left">
          {leftValue}
        </p>

        <div className="knob"></div>

        <p ref={setRightElement} className="value right">
          {rightValue}
        </p>
      </div>
    </div>
  )
}
