import {
  AlgorandIcon,
  BitcoinIcon,
  BloctoIcon,
  CosmosIcon,
  EthereumIcon,
  FlowIcon,
  MagicLinkIcon,
  SolanaIcon,
  StarknetIcon,
} from '@dynamic-labs/iconic'
import { classNames } from '@dynamic-labs/sdk-react-core'

const chainMap = {
  Ethereum: { Icon: <EthereumIcon />, style: {} },
  Solana: {
    Icon: <SolanaIcon />,
    style: { backgroundColor: 'black', padding: '5px' },
  },
  Algorand: {
    Icon: <AlgorandIcon style={{ filter: 'invert(1)' }} />,
    style: { backgroundColor: 'black', padding: '4px' },
  },
  Flow: { Icon: <FlowIcon />, style: {} },
  Starknet: { Icon: <StarknetIcon />, style: {} },
  Cosmos: { Icon: <CosmosIcon />, style: {} },
  Bitcoin: { Icon: <BitcoinIcon />, style: {} },
}

export function ChainIcon({ chain, selected }) {
  return (
    <div className={classNames('chain-icon', { selected })}>
      <div style={chainMap[chain].style} className="background">
        {chainMap[chain].Icon}
      </div>
    </div>
  )
}
