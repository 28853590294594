import { useMemo } from "react";

import { CopyBlock } from "../../components/CopyBlock";
import { ProjectSetupToggles } from "../../components/ProjectSetupToggles";
import {
  clientV2Map,
  connectorsV2Map,
  hasWagmiV2Map,
  packageManagerMap,
  useProjectSetupVariables,
} from "../../utils";

export function QuickstartCommandV2() {
  const { client, connectors, hasWagmi, packageManager } =
    useProjectSetupVariables();

  const output = useMemo(() => {
    return [
      packageManagerMap[packageManager].packagePath,
      "viem",
      hasWagmiV2Map[hasWagmi].packagePath,
      "@dynamic-labs/sdk-react-core",
      clientV2Map[client].packagePath,
      ...connectors.map((connector) => connectorsV2Map[connector].packagePath),
    ]
      .filter((command) => Boolean(command))
      .join(" ");
  }, [packageManager, client, hasWagmi, connectors]);

  return (
    <div className="quickstart-command">
      <ProjectSetupToggles />

      <CopyBlock
        output={output}
        settings={{ lineNumbers: false }}
        language="shell"
        highlightStrings={["npm i", "yarn add"]}
      />
    </div>
  );
}
