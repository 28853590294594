import { useEffect } from 'react'

export const useObserveResize = (target, callback) => {
  // Sync the observer to the callback and target state
  useEffect(() => {
    if (!target) return

    const newObserver = new ResizeObserver(callback)

    newObserver.observe(target)

    return () => newObserver.disconnect()
  }, [target, callback])
}
