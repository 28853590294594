import { useEffect, useState } from 'react'
import { Editor } from './Editor'
import { Sdk } from './Sdk'

const cssOverride = document.createElement('style')
document.head.append(cssOverride)

export const TemplateEditor = () => {
  const [css, setCss] = useState('')

  useEffect(() => {
    cssOverride.textContent = css
    setCss(css)
  }, [css])

  useEffect(() => {
    document.title = 'Dynamic Template Editor'
  }, [])

  return (
    <div className="template-editor-main-container">
      <div className="tab">
        <Editor css={css} setCss={setCss} />
      </div>

      <div className="tab">
        <Sdk />
      </div>
    </div>
  )
}
