import { useCallback, useEffect, useRef } from 'react'

export const useOnHover = (target, onHover, onLeave) => {
  const leaveTimeoutRef = useRef()

  const leave = useCallback(() => {
    if (!onLeave) return

    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current)
    }

    leaveTimeoutRef.current = setTimeout(() => {
      onLeave()
      leaveTimeoutRef.current = null
    }, 50)
  }, [onLeave])

  const enter = useCallback(() => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current)
    }

    onHover()
  }, [onHover])

  useEffect(() => {
    if (!target) return

    target.addEventListener('mouseover', enter)
    target.addEventListener('mouseout', leave)

    return () => {
      target.removeEventListener('mouseover', enter)
      target.removeEventListener('mouseout', leave)
    }
  }, [enter, leave, target])
}
