import { CopyBlock } from '../../components/CopyBlock'
import { ProjectSetupToggles } from '../../components/ProjectSetupToggles'
import { useQuickstartCode } from './useQuickstartCode'

export function QuickstartCode() {
  const { output, highlightStrings } = useQuickstartCode()

  return (
    <div className="quickstart-code">
      <ProjectSetupToggles hidePackageManager />

      <CopyBlock output={output} highlightStrings={highlightStrings} />
    </div>
  )
}
