import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import {
  DynamicContextProvider,
  DynamicEmbeddedWidget,
  DynamicWidget,
} from '@dynamic-labs/sdk-react-core'
import { useState } from 'react'
import { Toggle } from '../../../components'

export const Sdk = () => {
  const [showEmbedded, setShowEmbedded] = useState(true)
  const [darkMode, setDarkMode] = useState(false)

  return (
    <DynamicContextProvider
      settings={{
        environmentId: '83bee83b-81e0-44c3-843c-435ab186f470',
        apiBaseUrl: 'https://app.dynamic-preprod.xyz/api/v0',
        walletConnectors: [EthereumWalletConnectors],
      }}
      theme={darkMode ? 'dark' : 'light'}
    >
      <div className="template-editor-sdk">
        <div className="template-editor-actions-toggle">
                    {/* Hidden during design bounty program */}
          {/* <Toggle
          leftValue={'Embedded'}
          rightValue={'Modal'}
          setValue={(newValue) => setShowEmbedded(newValue === 'Embedded')}
          value={showEmbedded ? 'Embedded' : 'Modal'}
        /> */}

          <Toggle
            leftValue={'Dark'}
            rightValue={'Light'}
            setValue={(newValue) => setDarkMode(newValue === 'Dark')}
            value={darkMode ? 'Dark' : 'Light'}
          />
        </div>

        <div className="widget-wrapper">
          {showEmbedded ? (
            <DynamicEmbeddedWidget background="default" />
          ) : (
            <div className="widget-wrapper">
              <DynamicWidget />
            </div>
          )}
        </div>
      </div>
    </DynamicContextProvider>
  )
}
