import { useRef, useState } from 'react'
import { CheckIcon, CopyIcon, XIcon } from '../../assets'
import { LoadingSpinner } from '../LoadingSpinner'
import { classNames } from '@dynamic-labs/sdk-react-core'

const copyLabel = 'Copy code'
const copyingLabel = 'Copying...'
const copyFailedLabel = 'Failed to copy code. Try again'
const copySuccessLabel = 'Copied!'

export const CopyButton = ({ text, className }) => {
  const [copyText, setCopyText] = useState(copyLabel)
  const [copyIcon, setCopyIcon] = useState(<CopyIcon />)

  const copyTimeoutRef = useRef()

  const copy = async () => {
    if (copyTimeoutRef.current) {
      clearTimeout(copyTimeoutRef.current)
      copyTimeoutRef.current = undefined
    }

    setCopyText(copyingLabel)
    setCopyIcon(<LoadingSpinner />)

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText(copySuccessLabel)
        setCopyIcon(<CheckIcon />)
      })
      .catch(() => {
        setCopyText(copyFailedLabel)
        setCopyIcon(<XIcon />)
      })
      .finally(() => {
        copyTimeoutRef.current = setTimeout(() => {
          setCopyText(copyLabel)
          setCopyIcon(<CopyIcon />)
          copyTimeoutRef.current = undefined
        }, 2000)
      })
  }

  return (
    <div className={classNames(className, 'copy-button')} onClick={copy}>
      <div className="icon">{copyIcon}</div>
      {copyText}
    </div>
  )
}
