import {
  DynamicLogoIcon,
  useUserUpdateRequest,
} from '@dynamic-labs/sdk-react-core'
import { DynamicIcon } from '../../../assets'

export const UpdateUserWithModalView = () => {
  // Get the updateUserWithModal function from the useUserUpdateRequest hook
  const { updateUserWithModal } = useUserUpdateRequest()

  const updateField = (field) => {
    updateUserWithModal([field]).then(console.log).catch(console.log)
  }

  return (
    <div className="with-modal">
      <p className="explanation">
        Uses <DynamicLogoIcon /> <DynamicIcon className="name" />
        's modals to handle all the updates
      </p>

      <button onClick={() => updateField('firstName')}>
        Update First Name
      </button>
      <button onClick={() => updateField('email')}>Update Email</button>
    </div>
  )
}
