import { CopyBlock } from '../../components/CopyBlock'
import { ProjectSetupToggles } from '../../components/ProjectSetupToggles'
import { useQuickstartCodeV2 } from './useQuickstartCodeV2'

export function QuickstartCodeV2() {
  const { output, highlightStrings } = useQuickstartCodeV2()

  return (
    <div className="quickstart-code">
      <ProjectSetupToggles hidePackageManager />

      <CopyBlock output={output} highlightStrings={highlightStrings} />
    </div>
  )
}
