import Editor from '@monaco-editor/react'
import { CopyButton } from '../CopyButton/CopyButton'
import { LoadingSpinner } from '../LoadingSpinner'
import { monacoCustomizer } from './monacoCustomizer'

export const CopyBlock = ({
  output,
  language = 'javascript',
  settings = {},
  highlightStrings = [],
}) => (
  <div className="copy-block">
    <CopyButton className="prompt" text={output} />

    <Editor
      loading={<LoadingSpinner />}
      value={output}
      language={language}
      theme="dynamic"
      options={{ ...editorOptions, ...settings }}
      beforeMount={monacoCustomizer(highlightStrings, language)}
      height="100%"
      width="98%"
    />
  </div>
)

export const editorOptions = {
  copyWithSyntaxHighlighting: false,
  folding: false,
  fontFamily: 'DM Mono',
  fontSize: 14,
  fontWeight: '500',
  lineHeight: 20,
  lineNumbersMinChars: 2,
  minimap: { enabled: false },
  overviewRulerLanes: 0,
  readOnly: true,
  domReadOnly: true,
  scrollBeyondLastLine: false,
  scrollbar: { alwaysConsumeMouseWheel: true, vertical: 'auto' },
  tabSize: 2,
  wordWrap: 'on',
  wrappingIndent: 'same',
  wrappingStrategy: 'advanced',
  padding: { bottom: 16, top: 16 },
  'semanticHighlighting.enabled': true,
}
