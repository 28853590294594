/* eslint-disable multiline-comment-style */

import { useEffect, useRef, useState } from 'react'

/** A very simple hook that you can use to apply exit animations to components.
 * Just add a class with an exit animation to your component when "exiting" is true and the variable
 * automatically becomes false once the animation is over.
 */
export const useExitAnimation = ({ isOpen, animationTarget }) => {
  /** Whether the exit animation should be playing */
  const [exiting, _setExiting] = useState(false)

  /** Override the state to avoid flickering the same render cycle it's set to true */
  const overrideExiting = useRef(false)

  const setExiting = (value) => {
    _setExiting(value)
    overrideExiting.current = value
  }

  /** Previous state of isOpen */
  const previousIsOpen = useRef(isOpen)

  useEffect(() => {
    if (previousIsOpen.current === isOpen) return

    previousIsOpen.current = isOpen

    // Starts exit animation when isOpen turns false
    // Stops exit animation early if isOpen is set back to true halfway through
    setExiting(!isOpen)
  }, [isOpen])

  // Listen for animation end to disable exiting state
  useEffect(() => {
    if (!animationTarget) return

    const disableExiting = () => setExiting(false)

    animationTarget.addEventListener('animationend', disableExiting)

    return () =>
      animationTarget.removeEventListener('animationend', disableExiting)
  }, [animationTarget, isOpen])

  return exiting || overrideExiting.current
}
