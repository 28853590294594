import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import { TabView } from './views/TabView'
import {
  DynamicContextProvider,
  DynamicWidget,
  useIsLoggedIn,
} from '@dynamic-labs/sdk-react-core'

const PageView = () => {
  const isLoggedIn = useIsLoggedIn()

  if (isLoggedIn) return <TabView />

  return <DynamicWidget />
}

export const UseUserUpdateRequest = () => (
  <div className="use-user-update-request">
    <DynamicContextProvider
      settings={{
        environmentId: '4f712129-907a-474f-8d06-f7a3dd1318ea',
        apiBaseUrl: 'https://app.dynamic-preprod.xyz/api/v0',
        walletConnectors: [EthereumWalletConnectors],
      }}
    >
      <PageView />
    </DynamicContextProvider>
  </div>
)
