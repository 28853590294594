import { useState } from 'react'

import { DynamicEmbeddedWidget } from '@dynamic-labs/sdk-react-core'

const backgroundOptions = ['none', 'default', 'with-border']

export function EmbeddedWidget() {
  const [background, setBackground] = useState('default')

  return (
    <div className="embedded-widget-container">
      <div className="props">
        <p>Background setting</p>
        <select
          value={background}
          onChange={({ target }) => setBackground(target.value)}
        >
          {backgroundOptions.map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <DynamicEmbeddedWidget background={background} />
    </div>
  )
}
