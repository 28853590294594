import { useProjectSetupVariables } from '../../utils'
import { ConnectorSelect } from './ConnectorSelect/'
import { Toggle } from '../Toggle'

export const ProjectSetupToggles = ({ hidePackageManager }) => {
  const {
    client,
    connectors,
    hasWagmi,
    packageManager,
    setClient,
    setConnectors,
    setHasWagmi,
    setPackageManager,
  } = useProjectSetupVariables()

  return (
    <div className="project-setup-toggles">
      <div className="toggles">
        {/* Package manager */}
        {!hidePackageManager && (
          <Toggle
            value={packageManager}
            setValue={setPackageManager}
            leftValue={'npm'}
            rightValue={'yarn'}
            label="Package manager"
          />
        )}

        {/* Client library */}
        <Toggle
          value={client}
          setValue={setClient}
          leftValue={'viem'}
          rightValue={'ethers'}
          label="Client library"
        />

        {/* Wagmi */}
        <Toggle
          strongColors
          value={hasWagmi}
          setValue={setHasWagmi}
          label="Use wagmi"
        />
      </div>

      {/* Connectors */}
      <ConnectorSelect setValue={setConnectors} value={connectors} />
    </div>
  )
}
