import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'

/** This is a fix for mintlify iframes. Without this, when the page loads in dark mode,
 * the iframe renders with a white background for seemingly no reason */
export const ApplyColorScheme = () => {
  const [searchParams] = useSearchParams()

  const colorScheme = useMemo(
    () => searchParams.get('color-scheme'),
    [searchParams]
  )

  return (
    <Helmet>
      {colorScheme && <meta name="color-scheme" content={colorScheme} />}
    </Helmet>
  )
}
