import MonacoEditor from '@monaco-editor/react'
import { useEffect, useState } from 'react'
import { compileString } from 'sass'
import { CopyButton } from '../../../components'
import { useLocalStorage } from '../../../utils'
import { defaultValue } from './defaultValue'

export const Editor = ({ css, setCss }) => {
  const [showScss, setShowScss] = useState(true)

  const [scss, setScss] = useLocalStorage(defaultValue, 'template-editor-value')

  useEffect(() => {
    try {
      const newCss = compileString(scss).css

      setCss(newCss)
    } catch {}
  }, [setScss, setCss, scss])

  return (
    <div className="template-editor">
      <div className="actions">
        <CopyButton text={showScss ? scss ?? '' : css} />

        {/* Hidden during design bounty program */}
        {/* <Toggle
          leftValue={'SCSS'}
          rightValue={'CSS'}
          setValue={(newValue) => setShowScss(newValue === 'SCSS')}
          value={showScss ? 'SCSS' : 'CSS'}
        /> */}

        <a
          href="https://github.com/dynamic-labs/sdk-themes/tree/main/scss"
          target="_blank"
          rel="noreferrer"
          className="see-all-button"
        >
          All templates
        </a>
      </div>

      {showScss ? (
        <div className="monaco-editor">
          <MonacoEditor
            height="100%"
            defaultLanguage="scss"
            onChange={(newValue) => setScss(newValue ?? '')}
            value={scss}
            options={{
              ...defaultOptions,
              readOnly: false,
              domReadOnly: false,
            }}
            key="scss"
          />
        </div>
      ) : (
        <div className="monaco-editor">
          <MonacoEditor
            height="100%"
            defaultLanguage="css"
            value={css}
            onChange={() => {}}
            options={{
              ...defaultOptions,
              readOnly: true,
              domReadOnly: true,
            }}
            key="css"
          />
        </div>
      )}
    </div>
  )
}

const defaultOptions = {
  tabSize: 2,
  minimap: { enabled: false },
}
